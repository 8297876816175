@import "../template/mixin_templates";

/***** GENERAL *****/
body{
    font-family: Helvetica, Arial, sans-serif;
    margin: 0;
}
b, strong{
    text-align:center;
    font-weight: bold;
}
i, em{
    font-style: italic;
}
iframe{
    max-width:100%;
}
table{
    border-collapse: collapse;
    border-spacing: 0;
    box-sizing: border-box;
}
@include titreBaseSize();
.wrapper h1, .wrapper h2, .wrapper h3,
.wrapper h4, .wrapper h5, .wrapper h6,
#tinymce h1, #tinymce h2, #tinymce h3,
#tinymce h4, #tinymce h5, #tinymce h6,
.wrapper .h1, .wrapper .h2, .wrapper .h3,
.wrapper .h4, .wrapper .h5, .wrapper .h6,
#tinymce .h1, #tinymce .h2, #tinymce .h3,
#tinymce .h4, #tinymce .h5, #tinymce .h6{
    margin-bottom: .5rem;
    margin-top: .2rem;
    line-height: 1.4;
    font-weight: normal;
}
h2, .h2, .single-article .titre-article h1{
    font-size: 1.6rem;
    display: table;
    padding: 2px 20px;
    margin: auto;

    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
}
h2::before, h2::after,
.h2::before, .h2::after,
.single-article .titre-article h1::after,
.single-article .titre-article h1::before,
.single-article .titre-article .h1::after,
.single-article .titre-article .h1::before{
    position: absolute;
    top: 0;
    content: "";
    border-style: solid;
}
h2::before,
.h2::before,
.single-article .titre-article h1::before,
.single-article .titre-article .h1::before{
    left: -10px;
    border-width: 40px 10px 0 0;
}
h2::after,
.h2::after,
.single-article .titre-article h1::after,
.single-article .titre-article .h1::after{
    right: -10px;
    border-width: 0 0 40px 10px;
}
h2 span,
.h2 span{
    vertical-align: middle;
}
.wrapper h1,
.wrapper .h1{
    font-size: 2rem;
}
/*.wrapper h2{
    font-size: 1.8rem;
}*/
.wrapper h3,
.wrapper .h3{
    font-size: 1.6rem;
}
.wrapper h4,
.wrapper .h4{
    font-size: 1.5rem;
}
.wrapper h5,
.wrapper .h5{
    font-size: 1.4rem;
}
.wrapper h6,
.wrapper .h6{
    font-size: 1.3rem;
}
a{
    text-decoration: underline;
}
p{
    margin-bottom: 1.2rem;
    line-height: 1.4rem;
}
ul, ol{
    margin-bottom: .5rem;
}
li > ul{
    margin-left: 1.9rem;
    list-style-type: inherit;
}
li{
    line-height: 1.2;
}
blockquote{
    padding: 10px 10px;
    border-left: 1px solid #ccc;
    margin: 20px 0;
}
blockquote cite{
    display: block;
    font-size: .85rem;
    font-style: italic;
}


/***** HEADER *****/
header{
    padding-top: 8px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
}
body .header_p{
    background-repeat: no-repeat;
}


#headercontent {
    max-width: 1060px;
    height: 100px;
    margin: auto;
    position: relative;
    transition: height 0.3s ease-in-out;
    .header--onscroll & {
        height: 50px;
        .slogan {
            transition: height 0.3s ease-in-out;
            opacity: 0;
        }
        .logo img{
            height: 40px;
            width: auto;
        }
    }
}



.logo{
    position: absolute;
    top: 0;
    left: 0;
    display:inline-block;
    max-height: 100px;
}
.logo img, #headercontent img{
    /*    width: 100%;*/
    max-height: 100px;
    max-width: 100%;
    display: inline-block;
}
.slogan{
    font-size: 2rem;
    font-weight: bold;
    position: absolute;
    top: 28%;
    left: 24%;
    margin: 0;
}

nav#nav-principal, .nav-static-breadcrumb {
    width: 90%;
    text-align: center;
    margin: auto;
    padding-left: 10px;
    li{
        position:relative;
    }
    ul {
        display: inline-block;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .navigation_button_p {
        margin-bottom: 5px;
    }
    .navigation_link_p {
        text-decoration: none;
        /*    display: block;*/
        position: relative;
    }
    > ul > li {
        margin-right: 15px;
        /*    float: left;*/
        display: inline-block;

    }
    a {
        padding: 6px 5px;
        display: block;
        text-decoration: none;
        line-height: 1.2;
    }
    .navigation_button_p::before, .navigation_button_p::after {
        position: absolute;
        top: 0;
        content: "";
        border-style: solid;
    }
    .navigation_button_p::before {
        left: -10px;
        border-width: 31px 10px 0 0;
    }
    .navigation_button_p::after {
        right: -10px;
        border-width: 0 0 31px 10px;
    }
    .slide-toggle {
        clear: both;
        overflow: hidden;
        position: absolute;
        z-index: 1000;
        left: -10px;
    }
    li:hover > .slide-toggle{
        overflow:visible;
    }
    li {
        ul {
            /*position: absolute;
            top: 31px;
            left: -10px;*/
            min-width: calc(100% + 10px);
            white-space: nowrap;
            box-shadow: 1px 1px 12px #aaa;
            padding-left: 0;
            margin-left:-5px;

            transform-origin: top left;
            transform: scaleY(0) translateX(-50%);
            visibility: hidden;
            position: absolute;
            left:50%;
            z-index: 100;

            transition:transform .2s linear, visibility 0s linear .2s;

            ul {
                position: absolute;
                left: 100%;
                top: 0;
                transform: scaleY(0) translateX(0);
                margin-left:0;
            }
            li:hover > ul {
                display: block;
            }
        }
        &:hover > ul{
            visibility: visible;
            transform: scaleY(1) translateX(-50%);
            transition-delay: 0s;
        }
        li:hover > ul{
            transform: scaleY(1) translateX(0);
        }
    }

    li ul li {
        border-bottom: 1px solid #ebebeb;
    }
    li ul a {
        padding: 3px 10px 3px 15px;
        line-height: 1.7;
    }
    li ul a::before, li ul a::after {
        border: none;
    }
}

.nav-static-breadcrumb{
    width: 100%;
    box-sizing: border-box;
}

/***** FIN HEADER *****/


/***** CONTENU *****/

#main-conteneur{
    overflow: hidden;
    &:before{
        content:'';
        display: block;
        width: 100%;
        height: 1px;
    }
}

.wrapper-content{
    margin-top:10px;
    position:relative;
    //z-index: 1000;
}
.wrapper{
    width: 100%;
    max-width: 1080px;
    //min-height: 550px;
    margin: 0 auto 30px;
    box-sizing:border-box;
    padding: 30px 30px 50px 30px;
}

#pub {
    max-width: 1080px;
    margin: 0 auto;
}

.cmonsite-ficheproduit.cmonsite-template #bloc-fil-title{
    h2, .h2, h1, .h1{
        display:none;
    }
}

#bloc-fil-title .fils-separation{
    line-height: 29px;
}

aside.sidebar{
    display:none;
}

.wrapper.content_p.accueil_diaporama_template{
    min-height:0;
    padding:0;
}
.page img{
    max-width: 100%;
}

.content_p .nav-static-breadcrumb, #fiche-produit nav.secondaire {
    text-align: left;
    font-size: .8rem;
    margin: 0 0 20px 0;
}

.content_p .nav-static-breadcrumb a, #fiche-produit nav.secondaire a {
    border-bottom: 1px solid;
}

.content_p .nav-static-breadcrumb a.actif, #fiche-produit nav.secondaire a.actif {
    border: 1px solid;
}

a.bouton, .button, a.button, input[type=submit], a.addmsglo, .addbasket, .diaporama a, .content_p .nav-static-breadcrumb li:last-child a {
    padding: 7px 22px;
    border: 1px solid;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    box-sizing: border-box;
}
.content_p form.formulaires_perso input[type=submit], .form-newsletter input[type=submit]{
    padding: 7px 22px !important;
    border: 1px solid !important;
}

.formulaires_perso{
    border: 1px solid #ccc;
}
.form-newsletter{
    border-radius: 0;
}
.formulaires_perso .roundedgraymsg{
    border: none;
}
.formulaires_perso div.center, .form-newsletter .center{
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: normal;
    margin-bottom: 5px;
}
.formulaires_perso label.qs_form{
    border-bottom: none;
    padding-bottom: 0;
}
.formulaires_perso input, .formulaires_perso textarea, .form-newsletter input{
    box-sizing: border-box;
}
.formulaires_perso input, .form-newsletter input{
    height: 31px;
}
.formulaires_perso input[type=text]{
    width: 100% !important;
}
.formulaires_perso input[type=checkbox]{
    margin-bottom: 0;
    height: auto;
}
.formulaires_perso textarea{
    height: 150px;
    width: 100% !important;
}

/* Accueil */
.content_p .bx-wrapper .bx-controls-direction a, .content_p .banner_diaporama .bx-wrapper .bx-controls-direction a{
    z-index: 80;
}
.slide .center-img-slider{
    margin-top: 0;
}
.bx-wrapper .bx-viewport{
    left:0;
}
.slide-text{
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
}
.slide-text .content-slide-text > p, .slide-text .content-slide-text > a{
    /*    position: absolute;*/
    padding: 5px 50px 5px 10px;
}
.slide-text .content-slide-text > p{
    bottom: 52px;
    margin: 0 0 10px 0;
}
#bx-pager {
    text-align: center;
    margin-bottom: 50px;
}
#bx-pager > a {
    display: inline-block;
}
#bx-pager img {
    width: 150px;
}
/* Fin Accueil */


/* Boutique */

.container-etiquette{
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
    z-index: 100;
    .produit_etiquette{
        height: auto;
        width: 100%;
        position: absolute;
        &::after{
            border: none;
        }
    }
    h3, .h3{
        text-align: center;
        font-size: 1.2rem;
        padding: 3px 5px;
        z-index: 1;
        text-transform: none;
        margin: 0;
        white-space: nowrap;
    }
}

.template_boutique{
    margin: 20px 0;
}

.produits{
    margin: 0 0 30px;
    box-sizing: border-box;
    position: relative;
    padding: 0 10px 10px;
    .remise{
        z-index: 22;
    }
    .productContentWrap{
        padding:0 10px;
        box-sizing: border-box;
    }
    &.is_etiquette .remise, .remise{
        /*    display: inline;*/
        position: absolute;
        right: 10px;
        padding: 8px 4% 8px 5%;
        font-weight: bold;
        font-size: 1.2rem;
        display: block;
        border-radius: 20px 0 20px 0;
        box-sizing: border-box;
        top: 0;
    }
    &.is_etiquette .remise{
        top: 40px;
    }
    .productImageWrap{
        overflow: hidden;
        img{
            height: auto;
            display: block;
            width: 100%;
        }
    }
    .mask{
        margin: 0;
        text-align: center;
        width: 100%;
        left: 0;
        position: absolute;
    }
    .lien-mask {
        height: 100%;
        left: 0;
        filter: alpha(opacity=60);
        position: absolute;
        top: 0;
        width: 100%;
    }
    .zoom {
        z-index: 2;
        background: #000;
        color: #fff;
        font-size: 15px;
        padding: 8px 18px;
        text-align: center;
        text-decoration: none;
        display: block;
        margin: auto;
        box-sizing: border-box;
    }
    .nomprod{
        margin-top: 10px;
        a{
            text-decoration: none;
            text-transform: uppercase;
            text-align: center;
        }
    }
    .desc{
        line-height: 1.2rem;
        font-size: .9rem;
    }
    .prix{
        margin-top: 10px;
        font-size: .95rem;
        line-height: 1.2rem;
        text-align: center;
        .oldprix{
            text-decoration: line-through;
        }
        .oldprix, .prixprod{
            float: none;
            display: inline-block;
        }
    }

    .note-produit {
        text-align: center;
    }
}

.produits-accueil{
    .list-produits{
        margin-top: 10px;
    }
    &.template_boutique_accueil .produits .remise{
        left:auto;
        right:10px;
    }
}

.template_boutique_accueil{
    &.accueil_boutique_template_1{
        .block-btn-addbasket{
            margin: 0;
            .addbasket{
                max-width: 95%;
            }
        }
    }
    &.accueil_boutique_template_2{
        .attr{
            text-align: center;
            select{
                display: inline-block;
                float:none;
            }
        }
        .block-btn-addbasket{
            margin: 0;
            .addbasket{
                max-width: 95%;
                width: auto;
                text-align: center;
            }
        }
    }
}

.template_boutique{
    .produits {
        margin-bottom: 15px;
        padding-bottom: 15px;
    }
    &.template_boutique_1{
        .block-btn-addbasket{
            align-items: flex-end;
            justify-content: flex-start;
            margin: 0 0 15px;
            .addbasket{
                margin-top: 5px;
                padding: 10px;
                &:not(.next-select){
                    width: auto;
                    max-width: 80%;
                    margin: 0 auto;
                }
            }
        }
    }
    &.template_boutique_2{
        .attr select{
            right:-49%;
        }
    }
    &.template_boutique_3{
        .produits{
            &.is_etiquette .remise, .remise{
                left:10px;
                right:auto;
                top:48px;
            }
            &.is_etiquette{
                .nomprod{
                    margin-top: 10px;
                }
            }
            .attr{
                text-align: center;
                select{
                    display: inline-block;
                    float:none;
                }
            }
            .remise{
                padding:8px 15px;
            }
        }
    }
    &.template_boutique_4{
        .produits .remise{
            top:34px;
        }
        .container-etiquette .produit_etiquette{
            position: relative;
        }
        .attr select{
            width: 100%;
            left: 0;
        }
    }
    &.template_boutique_5 .produits .remise{
        right:auto;
    }
}

#fiche-produit{
    .triangle-ficheprod{
        position: absolute !important;
        top: 12px;
        right: 0;
        z-index: 100;
    }
    .remise-produit{
        font-size: 1rem;
        right: 0;
        height: 32px;
        width: 47px;
        padding: 14px 10px 1px 12px;
        border-radius: 25px 0 25px 0;
    }
    .wrap-images{
        .grande-image, .grande-image > a{
            height: auto;
        }
        .previews .previous, .previews .next{
            font-size: 2rem;
            line-height: 2.2;
        }
        button{
            width: 100%;
            padding: 5px;
            cursor: pointer;
            background-color: #e8e8e8;
            border: 1px solid #ccc;
            &:hover{
                background-color: #fff;
                border: 1px solid #8e8e8e;
            }
        }

    }
    .wrap-description{
        .prix{
            text-align: left;
            .oldprix{
                float: right;
            }
        }
        p.description{
            margin-top: 20px;
        }
    }
    .wrap-details h4, .wrap-details .h4{
        border-bottom: 1px solid #ccc;
        text-align: center;
        margin-bottom: 20px;
    }
    .pro-pannier{
        overflow: hidden;
        label{
            font-size: .9rem;
        }
    }
    .ajouter-panier {
        a{
            margin-top: 0;
            float: right;
        }
        i{
            margin-right: 7px;
        }
        &.withoutAttr {
            float: none;
            width: 100%;
        }
    }
    .container-comment #entete {
        margin-bottom: 15px;
        text-align: center;
    }
    form{
        .form-control{
            border-radius: 0;
        }
        .input-form-moyen{
            width: 43.5%;
        }
        .input-form-petit{
            width: 12.66667%;
        }
        textarea.form-control{
            min-height: 150px;
        }
    }
    #captcha input{
        padding: 13px;
        font-size: 1.2rem;
        height: auto;
        margin-top: -7px;
    }
    .details-ficheprod{
        text-align: right;
        font-size: .8rem;
    }
    p.zoom-img-position{
        *zoom:1;
        &::after, &::before{
            display:table;
            content:"";
            line-height:0
        }
        &::after{
            clear:both;
        }
    }
    .zoomPad > img {
        max-width: 100%;
    }
}

.template_fiche_produit {
    #fiche-produit .top_fiche .button{
        display:inline-block;
    }
    #fiche-produit{
        .wrap-description{
            .brand{
                &::after, &::before{
                    display: none;
                }
                color:inherit !important;
                background:transparent !important;
            }
            .h1, h1{
                margin:0 0 20px;
            }

            .bloc-quantite{
                margin-bottom: 14px;
                .bt-quantity{
                    height: auto;
                }
            }
        }
    }
    &.template_fiche_produit_1 #fiche-produit {
        .declinaison.row {
            //float: left;
            //width: 45%;
        }
        .triangle-ficheprod-second{
            top:50px;
            display: block !important;
        }
        .wrap-images{
            .remise-produit{
                display: none;
            }
        }
        .wrap-description .prix{
            clear: both;
            text-align: center;
            .oldprix, .prixprod{
                float: none;
                display: inline-block;
            }
        }
        .bloc-quantite, .ajouter-panier {
            //float: right;
            //width: 55%;
        }

        .ajouter-panier.block-addbasket{
            display: inline-block;
            width: auto;
            margin-left: 10px;
        }

    }
    &.template_fiche_produit_2 #fiche-produit {
        .declinaison.row{
            float:none;
            width: 100%;
        }
        .triangle-ficheprod{
            right: 0;
            left:auto;
            top: 0;
            .remise-produit{
                left:auto;
                right: 0;
                top:0;
                height: auto;
                width: auto;
                padding:14px 12px;
            }
        }
    }
}


#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > .button {
        flex: 0 1 auto;
        font-size: 1.5em;
        margin: 15px 0;
    }

    > .button + .button {
        margin-left: 15px;
    }
}

.template_fiche_produit_2 {

    .reservation__date .datepicker__month {
        width: 100%;
    }

    .datepicker__months:before {
        background: #dcdcdc;
        bottom: 0;
        content: '';
        display: none;
        left: 50%;
        position: absolute;
        top: 0;
        width: 1px
    }

    .datepicker__months {
        max-height: none;
    }

    .datepicker--submit .datepicker__months{
        max-height: 0;
    }
}


.modal-backdrop {
    z-index: 2040;
}
.modal {
    z-index: 2041;
}


.search-bar input{
    height: 29px;
    box-sizing: border-box;
}

/* Fin Boutique */

/* Galerie */

.list-photos{
    margin: 20px 0;
    .galerie{
        position: relative;
        .center-img{
            display: block;
            overflow: hidden;
            height: 100% !important;
            text-align: center;
        }
        .mask{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
        p{
            width: 100%;
            text-transform: none;
            font-size: 1rem;
            line-height: 1.3;
            padding: 5px 10px;
            transition:opacity .2s linear;
            text-align: center;
        }
        .addcommentaires{
            text-decoration: none;
            position: absolute;
            top: 6px;
            right: 8px;
            font-size: 1.4rem;
            display: none;
            border: none;
        }
        &:hover{
            .addcommentaires{
                display: block;
            }
            p{
                width: 100%;
            }
        }
    }
}

.template_album{
    &.template_album_4,
    &.template_album_5{
        .commentP{
            padding:10px;
            p{
                position: relative;
            }
        }
    }
}

/* Fin Galerie */

/* Livre d'or */

a.addmsglo{
    display: block;
    margin: 20px auto 30px;
    width: 335px;
    text-align: center;
}
.list-messages{
    &::before, &::after{
        content: "";
        display: table;
        line-height: 0;
    }
    &::after{
        clear: both;
    }
}
.message_lo{
    border: 1px solid #ccc;
    box-shadow: 0 0 1px #bbb;
    margin-bottom: 10px;
    margin-right: 1%;
    position: relative;
    background-color: #FFF;
    h4, .h4{
        font-size: .8rem;
        border-bottom: 1px solid #ccc;
        padding: 3px 10px;
        margin: 0;
        width: 95%;
    }
    p{
        margin: 0;
        padding: 0 5px;
    }
    .message{
        text-align: center;
        padding: 2px 10px;
        &::before, &::after{
            font-family: FontAwesome, sans-serif;
            display: block;
        }
        &::before{
            content: "\f10d";
            text-align: left;
        }
        &::after{
            content: "\f10e";
            text-align: right;
        }
    }
    p.web{
        border-top: 1px solid #ededed;
        font-size: .8rem;
    }
    time{
        display: block;
        text-align: right;
        font-size: .7rem;
        padding: 0 10px 5px 0;
    }
    .note{
        position: absolute;
        border-bottom: 1px solid #ccc;
        top: 0;
        right: 0;
        line-height: 1.5;
        margin: 0;
        width: 41px;
    }
}

.template_livreor_3 .IDLO time{
    margin-right:65px;
}

.template_livreor_1 .list-messages .message_lo h4,
.template_livreor_1 .list-messages .message_lo .h4{
    padding-right:70px;
}

.livre_dor .form{
    margin-top: 0;
    h2, .h2{
        margin-bottom: 30px;
    }
    .s4 .s12:last-child{
        display: none;
    }
    .col-xs-9 .s12{
        width: 370px;
    }
    textarea{
        height: 143px;
    }
    .button{
        margin-right: 90px !important;
    }
}

/* Fin Livre d'or */

/* Contact */
form{
    margin: 30px auto auto;
    label{
        display: block;
        margin-bottom: 8px;
    }
    input, select{
        height: 31px;
        box-sizing: border-box;
        vertical-align: middle;
    }
    textarea{
        padding: 5px 5px;
    }
    input, select, textarea{
        &:focus{
            border: 1px solid;
            box-sizing: border-box;
            outline: 0;
        }
    }
    input[type=submit]:focus{
        box-shadow: none;
    }
    input.button{
        height: auto;
        font-size: 1.2rem;
    }
    .form-group{
        margin-bottom: 15px;
    }
    .form-control{
        padding: 6px 12px;
        box-sizing: border-box;
    }

}
.s12 form{
    max-width: 800px;
}

.inmap{
    margin-bottom: 30px;
}

/* Fin Contact */

#connexion-inscription h2,
#connexion-inscription .h2{
    font-size: 1.4rem;
}
#information .connexion form{
    margin-top: 0;
}

.content_p img{
    max-width:100%;
    height:auto !important;
}


/***** FIN CONTENU *****/


/***** FOOTER *****/
.footer_p{
    clear:both;
}
.footer_p p{
    margin-bottom: .5rem;
}
#pub{
    clear:both;
}
.footer_p #pub, .footer_p .music{
    text-align: center;
    padding: 7px 5px;
}
.footer_p #pub{
    font-size: 1rem;
}
.footer_p img{
    height:auto !important;
    max-width: 100%;
}
.footer_p .music{
}
.footer_p .mention{
    text-align: center;
    padding: 15px 20px 10px;
}
.footer_p .mention a{
    font-size: .8rem;
    text-decoration: none;
    margin: 8px 0;
    display: inline-block;
}

/***** FIN FOOTER *****/

table{
    table-layout:fixed;
}

/***** IE *****/
/* IE 10 */
.ie10 form input.button, .ie form input.button{
    font-size: 1rem;
}
.ie10 .search-bar-icon, .ie .search-bar-icon{
    vertical-align: top;
}

/* IE 8 */
.ie8 #fiche-produit .triangle-ficheprod{
    border-width: 0 77px 77px 0;
}
.ie8 #fiche-produit .triangle-ficheprod .remise-produit{
    padding: 25px 16px 3px 15px;
}
.ie8 #fiche-produit .previews .previous, .ie8 #fiche-produit .previews .next{
    font-size: 33px;
}
.ie8 .remise, .ie8 .is_etiquette .remise{
    padding: 7px 5px;
}
.ie8 .list-photos .galerie{
    width: 32%;
}
.ie8 .message_lo{
    width: 48%;
}
.ie8 .message_lo .note{
    line-height: 1.4;
    padding: 3px 5px;
}
.ie8 .message_lo .message{
    padding: 22px 10px 10px;
}

#resume .table tbody .row .col img{
    max-width:100% !important;
    height:auto !important;
}

/* Template Panier */
body.cmonsite-panier-2 #nav-principal > ul{
    display: inline-block;
    float:none;
    max-width:100%;
    + .template-panier.template-panier-2{
        float:none;
        border:none !important;
        vertical-align: top;
        min-height:30px;
        height: 31px;
        margin-bottom:5px;
        #panier{
            &::before, &::after{
                position: absolute;
                top: 0;
                content: "";
                border-style: solid;
            }
            &::before{
                left: -10px;
                border-width: 31px 10px 0 0;
                border-left-color:transparent !important;
                border-top-color:transparent !important;
                border-bottom-color:transparent !important;
                border-right-color: inherit !important;
            }
            &::after{
                right: -10px;
                border-width: 0 0 31px 10px;
                border-right-color:transparent !important;
                border-top-color:transparent !important;
                border-bottom-color:transparent !important;
                border-left-color: inherit !important;
            }
            .paniertoggle{
                padding: 0;
            }
        }
        #panier #header-panier{
            padding:6px 10px 6px;
            .count-live{
                top:15px;
            }
        }
    }
}
body.cmonsite-panier-3 #blocContent .template-panier.template-panier-3{
    border:none !important;
    top:-10px !important;
}


/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
    .side-tabs.etapes,
    div#resume .block-recap-panier,
    div#resume div.next-step,
    div#information #connexion-inscription,
    div#information div.next-step,
    #infoscommande,
    div#finish .block-info-payment,
    div#finish div.next-step,
    div#resume h4,
    div#finish h4,
    .message-commande {
        width: 95%;
    }

    .side-tabs.etapes {
        padding-bottom: 15px;
    }

    .logo-carrier-none {
        width: auto;
    }

    h4 {
        margin: 0;
    }

    div#information .list_address {
        margin: 0 0 20px;
    }
}

#order .commandes-details{
    > td > div{
        border-bottom:none;
    }
    .infosclient.s6{
        &, &:first-child{
            width: 100%;
            margin:10px 0 0;
        }
    }
}

/***** MEDIA QUERIES *****/

@media #{$large-and-down} {
    .wrapper, header nav {
        width: $medium-screen;
        margin: 0 auto;
    }
    #headercontent {
        padding: 5px 10px;
        height: 90px;
    }
    .page img{
        height: auto;
    }
    .livre_dor .form .button {
        margin-right: 8% !important;
    }
    .form-newsletter input[type=text] {
        width: 85%;
    }
}

@media #{$medium-and-down} {
    .wrapper, header nav {
        width: $small-screen;
        margin: 0 auto;
    }

    nav#nav-principal{
        position:relative;
        min-height: 50px;
    }
    .template-panier-2 #panier::before,
    .template-panier-2 #panier::after{
        display: none !important;
    }

    .productImageWrap{
        margin-top: 28px;
    }
    .is_etiquette .remise, .remise, .produit_etiquette h3, .produit_etiquette .h3{
        font-size: 1rem;
    }
    .is_etiquette .remise{
        top: 30px;
    }
    .boutique .addbasket, .produits-accueil .addbasket{
        font-size: 14px;
        padding: 5px 10px;
    }
    #fiche-produit .addbasket{
        font-size: .9rem;
        padding: 5px 16px;
    }
    #fiche-produit .wrap-images .previews .previous, #fiche-produit .wrap-images .previews .next {
        font-size: 1.5rem;
        line-height: 3.2;
    }
    .list-photos .galerie p{
        font-size: 0.9rem;
        margin-bottom: .5rem;
    }

    form > .s8:last-child > .s12:last-child > .s8{
        width: 100%;
    }
    form > .s8:last-child > .s12:last-child > .s4{
        float: right;
        margin-top: -38px;
    }
    .form-newsletter input[type=text] {
        width: 82%;
    }

    a.bouton, .button, a.button, input[type=submit], a.addmsglo, .addbasket, .diaporama a{
        padding: 7px 13px;
    }
    #headercontent{
        height: 80px;
    }
    #headercontent .slogan{
        font-size: 1.8rem;
        margin: 12px auto !important;
    }
    .wrapper{
        margin: 0 auto 30px;
        padding: 25px 25px 30px;
    }
    .wrapper h1, .wrapper .h1 {
        font-size: 1.7rem;
    }
    .wrapper h2, .wrapper .h2 {
        font-size: 1.5rem;
    }
    h2::before,
    .h2::before{
        border-width: 35px 10px 0 0;
    }
    h2::after,
    .h2::after{
        border-width: 0 0 35px 10px;
    }
    .wrapper h3, .wrapper .h3 {
        font-size: 1.4rem;
    }
    .wrapper h4, .wrapper .h4 {
        font-size: 1.3rem;
    }
    .wrapper h5, .wrapper .h5 {
        font-size: 1.2rem;
    }
    .wrapper h6, .wrapper .h6 {
        font-size: 1.1rem;
    }
    blockquote {
        padding: 1px 10px;
        margin: 10px 0;
    }

    .is_etiquette .remise, .remise, .produit_etiquette h3, .produit_etiquette .h3{
        font-size: .95rem;
    }
    .produit_etiquette h3, .produit_etiquette .h3{
        padding: 3px 2px;
    }
    .nomprod, .desc, .produits .prix{
        font-size: .86rem;
    }
    .produits .zoom{
        font-size: .9rem;
        padding: 8px 18px;
    }
    .zoom:before{
        font-size: .9rem;
    }
    a.bouton, .button, a.button, input[type=submit], a.addmsglo, .addbasket, .diaporama a{
        padding: 7px 16px;
    }
    #fiche-produit .form-group{
        padding: 0 8px;
    }
    .formulaires_perso div.center, .form-newsletter .center{
        font-size: 1.2rem;
    }
    .list-photos .galerie p{
        font-size: 0.75rem;
    }

    .message_lo{
        width: 100%;
    }
    .message_lo h4,
    .message_lo .h4{
        font-size: .75rem;
    }
    .livre_dor .form .col-xs-9 .s12 {
        width: 100%;
    }

    .wrapper{
        padding: 25px 20px 30px 20px;
    }
    .slide-text{
        bottom: 20%;
    }
    #fiche-produit form .input-form-moyen{
        width: 42%;
    }
    #fiche-produit form .form-group{
        padding: 0 5px;
    }
    .search-bar .search-bar-icon{
        width: 8%;
    }
    .search-bar button{
        padding: 6px 27%;
    }
    .form-newsletter{
        input[type=text], input[type=submit]{
            display: block;
            margin: 3px auto;
        }
        input[type=text] {
            width: 90%;
        }
        input[type=submit]{
            width: 86px;
        }
    }
    .boutique .addbasket, .produits-accueil .addbasket{
        font-size: 13px;
    }
    #resume .button, #information .button{
        padding: 7px 8px;
    }

    .template_fiche_produit {
        &.template_fiche_produit_1 #fiche-produit {
            .declinaison.row {
                float: none;
                width: 100%;
            }
            .ajouter-panier {
                float: none;
                width: 100%;
            }
        }
        &.template_fiche_produit_2 #fiche-produit {
            .triangle-ficheprod{
                top: 45px;
            }
            #sliderImagePreviews{
                margin-top: 45px;
            }
        }
    }
}

@media #{$medium-and-up}{
    .menu_burger{
        display:none;
    }
}

@media #{$small-and-down} {
    /* Tableaux responsive */
    @import '../../design/helper/responsive/mobile/mobile';

    .template_boutique.template_boutique_2 .attr select{
        right:0;
    }

    #minisite-sidebar{
        display: none;
    }

    #headercontent{
        height:auto !important;
    }
    .slogan{
        left:0 !important;
        top:0 !important;
        right:auto !important;
        bottom:auto !important;
        text-align:center !important;
        font-size:18px !important;
        width:100% !important;
        position:static !important;
        padding-bottom:15px !important;
        float:none !important;
        height: auto !important;
    }
    .logo{
        padding-bottom: 15px;
        position: static !important;
        display:block !important;
        margin:0 auto !important;
        padding-top:15px;
        height:auto !important;
        text-align:center;
        margin-bottom:15px;
        width: 100% !important;
        float:none !important;
        img{
            max-height: 70px !important;
            width: auto !important;
            max-width:100% !important;
            height:auto !important;
            position:static !important;

        }
    }
    .wrapper {
        width: 100%;
        margin: 0 auto;
    }
    header{
        position:relative !important;
    }
    .wrapper-content{
        margin-top:20px !important;
    }
    header nav#nav-principal {
        width: 100%;
        margin: 0 auto;
    }
    #block-flottant-search{
        display:none;
    }
    .wrapper{
        padding: 18px 15px 25px;
    }

    #fiche-produit .triangle-ficheprod{
        border-width: 0 68px 68px 0;
    }
    #fiche-produit .triangle-ficheprod .remise-produit{
        font-size: .9rem;
        padding: 8px 9px 5px 15px;
    }
    .wrapper #fiche-produit .wrap-images .previews .list-previews{
        width: 63%;
    }
    .wrapper #fiche-produit .previews .previous, .previews .next{
        width: 14%;
    }
    #fiche-produit .wrap-description .prix .prixprod {
        font-size: 1.1rem;
    }
    #fiche-produit .declinaison, #fiche-produit .ajouter-panier{
        width: 100%;
        float: left;
        text-align: left;
    }
    #fiche-produit .button{
        font-size: .8rem;
        padding: 3px 14px;
    }
    #fiche-produit .addbasket i{
        display: none;
    }
    #fiche-produit form .form-group{
        width: 100%;
    }
    .container-comment > div{
        padding: 10px;
    }
    .wrapper #fiche-produit #captcha, #fiche-produit #captcha img{
        width: 100%;
    }
    #fiche-produit #captcha input{
        padding: 13px 8px;
    }
    .wrapper #fiche-produit #captcha div:first-child{
        margin-right: 3%;
    }
    #captcha div, .wrapper #fiche-produit #captcha div:last-child{
        margin: auto;
        width: 48%;
    }

    .list-photos .galerie{
        width: 100%;
        //margin: 1%;
    }
    .list-photos .galerie:nth-child(2n){
        margin-right: 0;
    }
    .list-photos .galerie p{
        margin-bottom: .4rem;
    }
    .form-newsletter input[type=text]{
        width: 95%;
    }
    #resume .button, #information .button {
        padding: 7px 8px;
        font-size: 13px;
    }

    /* Nav mobile */
    header.header_p{
        background-position: 0 center;
    }
    body.cmonsite-panier-2 #nav-principal > ul{
        display: none;
    }
    header nav#nav-principal {
        text-align: left;
        border: 1px solid #ccc;
        margin-bottom: 10px;
        padding: 0;
        position: relative;
        box-sizing:border-box;
        > ul{
            display:none;
        }
        &.nav_ouverte > ul{
            display:block !important;
        }
    }
    nav#nav-principal ul li, .navigation_button_p{
        margin:0 !important;
        width:100%;
        border:0 !important;
        box-sizing:border-box;
        &::after, &::before{
            display:none !important;
        }
    }
    nav#nav-principal li ul ul{
        position:relative;
        left:0;
        top:0;
    }
    .menu_burger{
        cursor: pointer;
        padding:15px 5px !important;
    }
    .cmonsite-panier-2 .menu_burger{
        padding:15px 60px 15px 5px !important;
    }
    .menu_burger::before{
        font-family: FontAwesome, sans-serif;
        content: "\f0c9";
        margin-right: 5px;
        left:auto;
        position:static;
    }
    nav#nav-principal .slide-toggle{
        overflow:visible;
    }
    header nav>ul{
        display: none;
    }
    nav#nav-principal > ul{
        display: block;
        position: absolute;
        width: 100%;
    }
    header nav>ul>li{
        float: none;
        margin-right: 0;
        display: block;
    }
    nav .navigation_button_p {
        margin-bottom: 0;
        border-bottom: 1px solid #ccc;
    }
    .navigation_button_p::before, .navigation_button_p::after{
        border: none;
    }
    .hasDropdown{
        position: relative;
    }
    .dropDown{
        position: absolute !important;
        right: 2%;
        top:0;
        font-weight: bold;
        cursor: pointer;
        text-align: center;
        font-size: 1.2rem;
        display:inline-block;
        height:32px;
        width:40px;
    }
    header nav#nav-principal {
        text-align: center;

        li ul {
            display: block;
            transform: none;
            left:0;
            top:100%;
            visibility: visible;
            li ul{
                transform: none;
                left:0;
                top:100%;
                visibility: visible;
            }
        }
        li:hover > ul{
            transform: none;
        }
        li ul li:hover > ul{
            transform: none;
        }
        .slide-toggle {
            position: relative;
            left: 0;
        }
        li.sousmenu_ouvert > div {
            overflow: visible;
        }
        li.sousmenu_ouvert > div > ul {
            display: block !important;
            position: absolute;
            height: auto;
        }
    }

    #fiche-produit #captcha{
        width: 100%;
    }
    .g-recaptcha iframe{
        width: 104% !important;
    }
    .rc-anchor-standard{
        width: 100% !important;
    }
    .rc-anchor-content{
        width: 64% !important;
    }
    .rc-anchor-content .rc-anchor-item:last-child{
        width: 67% !important;
    }
    .rc-anchor-privacy{
        width: 93%;
    }
    a.addmsglo{
        width: 100%;
    }
    .list-photos .galerie p{
        //display: none !important;
    }

    .productImageWrap{
        //height: 155px;
    }
    .is_etiquette .remise, .remise{
        font-size: .8rem;
    }
    .is_etiquette .remise {
        top: 28px;
    }
    .wrapper .produit_etiquette h3,
    .wrapper .produit_etiquette .h3{
        font-size: .9rem;
        line-height: 1.5;
    }

    .hors-stock{
        height: auto;
        box-sizing: border-box;
        padding-bottom: 0;
    }

    .zoom:before{
        margin-right: 5px;
    }
    .nomprod, .desc, .produits .prix {
        font-size: 0.75rem;
    }

    #paniercommandecontent #totalbloc{
        width: 235px;
    }
    #paniercmd #infoscommande fieldset{
        padding-left: 5px;
    }
    form input.button{
        font-size: 1em;
    }
    .form-newsletter input[type=text]{
        width: 100%;
    }
    #connexion-inscription .inscription{
        margin-bottom: 20px;
    }
    .produits .oldprix{
        font-size: 0.65rem;
    }

    .template_boutique_3 .is_etiquette .remise, .template_boutique_3 .remise{
        left:auto;
        right:auto;
    }
}

@media screen and (max-width: 400px){
    aside#minisite-sidebar{
        display: none;
    }
    .boutique .addbasket:before, .produits-accueil .addbasket:before, .zoom:before{
        content: none;
    }
}

@media screen and (max-width: 360px){
    body {
        font-size: 0.85rem;
    }
    .wrapper h1, .wrapper .h1 {
        font-size: 1.4rem;
    }
    .wrapper h2, .wrapper .h2 {
        font-size: 1.2rem;
    }
    h2::before,
    .h2::before{
        border-width: 27px 10px 0 0;
    }
    h2::after,
    .h2::after{
        border-width: 0 0 27px 10px;
    }
    .wrapper h3, .wrapper .h3 {
        font-size: 1.1rem;
    }
    .wrapper h4, .wrapper .h4 {
        font-size: 1rem;
    }
    .wrapper h5, .wrapper .h5 {
        font-size: .9rem;
    }
    .wrapper h6, .wrapper .h6 {
        font-size: .85rem;
    }
    .wrapper{
        padding: 20px 8px 30px;
    }

    .hors-stock{
        font-size: .9em;
    }
    .wrapper #fiche-produit .wrap-images{
        width: 100%;
    }
    .container-comment > div{
        padding: 20px 5px !important;
    }
    .wrapper #fiche-produit #captcha{
        width: 100%;
    }
    #fiche-produit #captcha img{
        margin-bottom: 5px;
    }
    .wrapper #fiche-produit #captcha > div, #fiche-produit #captcha div img{
        float: none;
        margin: auto;
    }
    .wrapper #fiche-produit #captcha div:first-child{
        margin: auto;
        width: 58%;
    }

    .search-bar .search-bar-icon {
        width: 11%;
    }
    .search-bar input, .search-bar button{
        padding: 5px 6px;
    }


    a.addmsglo{
        width: 100%;
    }
    .message_lo h4,
    .message_lo .h4{
        font-size: .75rem;
        width: 93.5%;
    }

    .formulaires_perso div.center, .form-newsletter .center{
        font-size: 1rem;
    }

    .wrapper .row .col{
        padding: 0 0.2rem;
    }
    body #panier{
        width: 236px;
    }
    #panier #header-panier{
        padding: 4px 5px 6px;
    }
    #panier .ajout_panier{
        margin: 6px;
    }
    #paniercommandecontent #totalbloc{
        width: 220px;
        float: none;
        margin: auto;
    }
    form input.button{
        font-size: .9em;
    }
    a.bouton, .button, a.button, input[type="submit"], a.addmsglo, .addbasket, .diaporama a{
        padding: 5px 14px;
    }
    #paniercmd #code_reponse{
        width: 274px;
    }

}

@media screen and (max-width: 320px){
    .wrapper {
        padding: 15px 10px 25px;
    }
    .wrapper h1, .wrapper .h1 {
        font-size: 1.2rem;
    }
    .wrapper h2, .wrapper .h2 {
        font-size: 1.1rem;
    }
    h2::before,
    .h2::before{
        border-width: 25px 10px 0 0;
    }
    h2::after,
    .h2::after{
        border-width: 0 0 25px 10px;
    }
    .wrapper h3, .wrapper .h3 {
        font-size: 1rem;
    }
    .wrapper h4, .wrapper .h4 {
        font-size: .9rem;
    }
    .wrapper h5, .wrapper .h5 {
        font-size: .85rem;
    }
    .wrapper h6, .wrapper .h6 {
        font-size: .825rem;
    }
    blockquote{
        padding: 0 10px;
    }

    .productImageWrap{
        height: 130px;
    }

    .wrapper .produit_etiquette h3, .wrapper .produit_etiquette .h3, .is_etiquette .remise, .remise{
        font-size: .8rem;
    }
    .is_etiquette .remise {
        top: 26px;
    }

    /*    .boutique .addbasket, .produits-accueil .addbasket{
            margin-top: 50px;
        }*/


    .message_lo h4,
    .message_lo .h4{
        font-size: .7rem;
    }
}

/* Diaporama accueil */
.accueil_diaporama_template{
    margin-bottom:0;
    .slide .slide-text{
        .addcommentaires{
            width:75px;
            height:55px;
            box-sizing:border-box;
            top:30px;
            right:30px;
            margin:0;
            padding:0;
            line-height:55px;
            text-align: center;
        }
        .content-slide-text > p{
            top: 180px;
            right: 30px;
            margin: 0;
            left: auto;
            bottom:auto;
        }
        .content-slide-text > a.slider-see-more{
            top: 105px;
            right: 30px;
            left:auto;
            padding: 0 10px;
            height: 55px;
            line-height: 55px;
            margin:0;
        }
        &.nolink{
            .content-slide-text > p{
                top: 105px;
                right: 30px;
                margin: 0;
                left: auto;
                bottom:auto;
            }
        }
    }

    &.accueil_diaporama_template_5{
        .slide .slide-text{
            box-sizing: border-box;
            .content-slide-text{
                .addcommentaires{
                    padding:10px;
                    height:auto;
                    width:auto;
                    top:10px;
                    right:10px;
                    line-height: initial;
                }
                > a.slider-see-more, > p{
                    left:10px;
                    right:auto;
                    box-sizing: border-box;
                }
                > a.slider-see-more{
                    top:10px;
                }
                > p{
                    bottom:10px;
                    top:auto;
                    padding:10px 5px;
                }
            }
        }
        .bx-wrapper {
            .slider-see-more {
                max-width: 90%;
                box-sizing: border-box;
            }
        }
    }
}

.wrapper.content_p.accueil_diaporama_template.accueil_diaporama_template_6{
    padding: 10px 10px 0;
}

@media #{$small-and-down} {
    .accueil_diaporama_template{
        .slide .slide-text{
            .addcommentaires{
                top:5px;
                right:5px;
            }
            .content-slide-text > p{
                top:auto;
                right:auto;
                left:5px;
                bottom:5px;
            }
            .content-slide-text > a.slider-see-more{
                top:5px;
                right:auto;
                left:5px;
                height:auto;
                bottom:auto;
                max-width:calc(100% - 75px);
            }
            &.nolink{
                .content-slide-text > p{
                    top:auto;
                    right:auto;
                    left:5px;
                    bottom:30px;
                }
            }
        }
    }
}
@media #{$medium-and-up} {
    .slide-toggle{
        min-width:calc(100% + 10px);
    }
}

.cmonsite-ficheproduit.cmonsite-template #bloc-fil-title{
    h2, .h2, h1, .h1{
        display:none;
    }
}

#bloc-fil-title .fils-separation{
    line-height: 29px;
}

/* Pendant l'édition, règle le problème de décalage du menu */
.modif-design header{
    margin-left: 239px;
    width: calc(100% - 239px);
}

.zoomContainer{
    z-index: 1000;
}